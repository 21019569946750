body{
  background-color: #eff2f2
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
  
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-cell{
  cursor: pointer;
}

.ant-menu-item-selected {
  background-color: #172D35 !important;
}

iframe {
  display: none;
}

.ant-statistic .ant-statistic-title {
  color: rgba(0,0,0,0.75) !important;
  font-weight: 500;
}